import AnimatedValue from '@/components/common/AnimatedValue'
import DateTimePicker from '@/components/controls/DateTimePicker'
import Select from '@/components/controls/Select'
import CourseSelectTemplate from '@/components/controls/Select/templates/Course'
import declination from '@/helpers/declination'
import { loadFromLS, saveToLS } from '@/helpers/localStorage'
import { showError } from '@/helpers/notifications'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { Component, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import './style.scoped.scss'

interface ICourse {
  id: number
  title: string
  preview_url: string
  preview_color: string
  type: 'free' | 'paid'
}

interface IPaymentStats {
  amount: number
  count: {
    all: number
    pre: number
    full: number
    post: number
  }
}

type PeriodOption = {
  label: string
  fn: (this: PaymentsFilters) => void
}

type Filter = {
  courseId: number | null
  startDate: string
  endDate: string
}

@Component
export default class PaymentsFilters extends tsx.Component<{
  onUpdateFilters: (props: Filter) => void
}> {
  pending = false
  courses: ICourse[] = []
  selectedCourse: ICourse | null = null
  startDate = ''
  endDate = ''
  stats: IPaymentStats = {
    amount: 0,
    count: { all: 0, full: 0, post: 0, pre: 0 },
  }

  periodOptions: PeriodOption[] = [
    {
      label: 'Сегодня',
      fn: function () {
        this.startDate = this.formatDate(new Date())
        this.endDate = this.formatDate(new Date())
      },
    },
    {
      label: 'Вчера',
      fn: function () {
        const date = new Date()
        date.setDate(date.getDate() - 1)
        this.startDate = this.formatDate(date)
        this.endDate = this.formatDate(date)
      },
    },
    {
      label: '7 дней',
      fn: function () {
        const date = new Date()
        date.setDate(date.getDate() - 6)
        this.startDate = this.formatDate(date)
        this.endDate = this.formatDate(new Date())
      },
    },
    {
      label: '14 дней',
      fn: function () {
        const date = new Date()
        date.setDate(date.getDate() - 13)
        this.startDate = this.formatDate(date)
        this.endDate = this.formatDate(new Date())
      },
    },
    {
      label: 'Этот месяц',
      fn: function () {
        const date = new Date()
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        this.startDate = this.formatDate(firstDay)
        this.endDate = this.formatDate(new Date())
      },
    },
    {
      label: 'Прошлый месяц',
      fn: function () {
        const date = new Date()
        const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
        const lastDay = new Date(date.getFullYear(), date.getMonth(), 0)
        this.startDate = this.formatDate(firstDay)
        this.endDate = this.formatDate(lastDay)
      },
    },
    {
      label: 'Позапрошлый месяц',
      fn: function () {
        const date = new Date()
        const firstDay = new Date(date.getFullYear(), date.getMonth() - 2, 1)
        const lastDay = new Date(date.getFullYear(), date.getMonth() - 1, 0)
        this.startDate = this.formatDate(firstDay)
        this.endDate = this.formatDate(lastDay)
      },
    },
    {
      label: 'Этот квартал',
      fn: function () {
        const date = new Date()
        const month = date.getMonth()
        let firstQuarterMonth = 0

        if (month > 2) {
          firstQuarterMonth = 3
        }
        if (month > 5) {
          firstQuarterMonth = 6
        }
        if (month > 8) {
          firstQuarterMonth = 9
        }

        const firstDay = new Date(date.getFullYear(), firstQuarterMonth, 1)

        this.startDate = this.formatDate(firstDay)
        this.endDate = this.formatDate(new Date())
      },
    },
    {
      label: 'Прошлый квартал',
      fn: function () {
        const date = new Date()
        const month = date.getMonth()
        let firstQuarterMonth = 9
        let lastQuarterMonth = 12

        if (month > 2) {
          firstQuarterMonth = 0
          lastQuarterMonth = 3
        }
        if (month > 5) {
          firstQuarterMonth = 3
          lastQuarterMonth = 6
        }
        if (month > 8) {
          firstQuarterMonth = 6
          lastQuarterMonth = 9
        }

        const year = month < 3 ? date.getFullYear() - 1 : date.getFullYear()

        const firstDay = new Date(year, firstQuarterMonth, 1)
        const lastDay = new Date(year, lastQuarterMonth, 0)

        this.startDate = this.formatDate(firstDay)
        this.endDate = this.formatDate(lastDay)
      },
    },
    {
      label: 'Этот год',
      fn: function () {
        const date = new Date()
        const firstDay = new Date(date.getFullYear(), 0, 1)
        this.startDate = this.formatDate(firstDay)
        this.endDate = this.formatDate(new Date())
      },
    },
  ]

  @Watch('selectedCourse')
  @Watch('startDate')
  @Watch('endDate')
  async updateData() {
    const filter: Filter = {
      courseId: this.selectedCourse?.id || null,
      startDate: this.startDate,
      endDate: this.endDate,
    }

    this.$emit('updateFilters', filter)
    saveToLS('paymentsFilter', filter)

    await this.fetchData()
  }

  async mounted() {
    await this.fetchCourses()

    const filter = loadFromLS<Filter>('paymentsFilter')

    if (filter) {
      this.startDate = filter.startDate
      this.endDate = filter.endDate
      this.selectedCourse =
        this.courses.find(c => c.id === filter.courseId) || null
    }

    await this.fetchData()
  }

  async fetchCourses() {
    this.pending = true
    try {
      const response: AxiosResponse<{ courses: ICourse[] }> = await axios.get(
        'v1/courses',
      )

      this.courses = response.data.courses.filter(c => c.type === 'paid')
      //@ts-ignore //TODO
      this.courses.forEach(c => (c.title = c.title.ru))
    } catch (error) {
      console.error(error)
      showError('При загрузке курсов произошла ошибка')
    }
    this.pending = false
  }

  async fetchData() {
    if (this.pending) {
      return
    }

    this.pending = true
    try {
      const courseQuery = this.selectedCourse
        ? `&course=${this.selectedCourse.id}`
        : ''
      const startDateQuery = this.startDate
        ? `&dateStart=${this.startDate}`
        : ''
      const endDateQuery = this.endDate ? `&dateEnd=${this.endDate}` : ''

      const response: AxiosResponse<IPaymentStats> = await axios.get(
        `v1/payments/sum?${startDateQuery}${endDateQuery}${courseQuery}`,
      )

      this.stats = response.data
    } catch (error) {
      console.error(error)
      showError('При получении данных произошла ошибка')
    }
    this.pending = false
  }

  formatDate(date: Date) {
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - offset * 60 * 1000)
    return date.toISOString().split('T')[0]
  }

  protected render() {
    return (
      <div>
        <div class="filters">
          <Select
            value={this.selectedCourse}
            onInput={value => (this.selectedCourse = value)}
            options={this.courses}
            disabled={this.pending}
            label="title"
            emptyFilterMessage="нет подходящих курсов"
            placeholder="Выберите курс"
            elementDeclination={['курс', 'курса', 'курсов']}
            scopedSlots={{
              selectedOption: (option: ICourse) => (
                <CourseSelectTemplate
                  title={option.title}
                  image={option.preview_url}
                  hex={option.preview_color}
                  selected
                />
              ),
              option: ({ option }: { option: ICourse }) => (
                <CourseSelectTemplate
                  title={option.title}
                  image={option.preview_url}
                  hex={option.preview_color}
                />
              ),
            }}
          />
          <div class="period">
            <DateTimePicker
              value={this.startDate}
              onInput={value => (this.startDate = value)}
              dateFormat="Y-m-d"
              displayedFormat="d.m.Y"
              disabled={this.pending}
            />
            <span class="mx-50">-</span>
            <DateTimePicker
              value={this.endDate}
              onInput={value => (this.endDate = value)}
              dateFormat="Y-m-d"
              displayedFormat="d.m.Y"
              disabled={this.pending}
            />
            <b-dropdown
              right
              variant="flat-primary"
              class="ml-25"
              toggle-class="px-1"
              disabled={this.pending}
              scopedSlots={{
                'button-content': () => (
                  <feather-icon icon="CalendarIcon" size="16" />
                ),
              }}
            >
              {this.periodOptions.map(o => (
                <b-dropdown-item onClick={() => o.fn.bind(this)()}>
                  {o.label}
                </b-dropdown-item>
              ))}
            </b-dropdown>
          </div>
        </div>

        <div class="stats d-flex py-1 px-2 flex-wrap rounded my-1">
          <div class="mr-2" style={{ minWidth: '110px' }}>
            <h3 class="m-0 text-success">
              <AnimatedValue
                value={this.stats.amount}
                scopedSlots={{ default: ({ value }) => `$${value}` }}
              />
            </h3>
            <small>Доход</small>
          </div>
          <div class="separator mr-3 d-none d-sm-block" />
          <div class="mr-2" style={{ minWidth: '70px' }}>
            <h3 class="m-0">
              <AnimatedValue value={this.stats.count.all} />
            </h3>
            <small>
              {declination(this.stats.count.all, [
                'Платёж',
                'Платежа',
                'Платежей',
              ])}
            </small>
          </div>
          <div class="mr-2" style={{ minWidth: '70px' }}>
            <h3 class="m-0 text-info">
              <AnimatedValue
                value={this.stats.count.full + this.stats.count.pre}
              />
            </h3>
            <small>
              {declination(this.stats.count.full + this.stats.count.pre, [
                'Новый',
                'Новых',
                'Новых',
              ])}
            </small>
          </div>
          <div style={{ minWidth: '70px' }}>
            <h3 class="m-0 text-warning">
              <AnimatedValue value={this.stats.count.post} />
            </h3>
            <small>
              {declination(this.stats.count.post, [
                'Доплата',
                'Доплаты',
                'Доплат',
              ])}
            </small>
          </div>
        </div>
      </div>
    )
  }
}
