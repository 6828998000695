import { Vue, Component, Ref } from 'vue-property-decorator'
import PaymentsFilters from '@/components/payments/Filters'
import PaymentsTable from '@/components/payments/PaymentsTable'

@Component
export default class PaymentsPage extends Vue {
  @Ref() readonly paymentTable!: PaymentsTable
  courseId: number | null = null
  startDate = ''
  endDate = ''

  protected render() {
    return (
      <div>
        <PaymentsFilters
          onUpdateFilters={filter => {
            this.courseId = filter.courseId
            this.startDate = filter.startDate
            this.endDate = filter.endDate

            // this.$nextTick(this.paymentTable.refresh)
          }}
        />
        <PaymentsTable
          ref="paymentTable"
          // courseId={this.courseId}
          // startDate={this.startDate}
          // endDate={this.endDate}
        />
      </div>
    )
  }
}
