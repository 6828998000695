import { CreateElement, VNodeData } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class AnimatedValue extends tsx.Component<{
  value: number
  time?: number
  tag?: string
  from?: number
  fixed?: number
  useGrouping?: boolean
}> {
  declare $scopedSlots: tsx.InnerScopedSlots<{
    default: { value: string }
  }>
  @Prop({ type: String, default: 'div' })
  readonly tag!: string
  @Prop({ type: Number, required: true })
  readonly value!: number
  @Prop({ type: Number, default: 1000 })
  readonly time!: number
  @Prop({ type: Number, default: 0 })
  readonly fixed!: number
  @Prop({ type: Boolean, default: false })
  readonly useGrouping!: boolean

  interval: ReturnType<typeof setInterval> | null = null
  currentValue: number = 0
  tickInterval = 17
  observer: IntersectionObserver | null = null

  @Watch('value')
  onValueChanged() {
    this.startAnimation()
  }

  get formatedValue() {
    let value = this.currentValue.toFixed(this.fixed)
    return this.useGrouping ? parseFloat(value).toLocaleString('ru') : value
  }

  mounted() {
    this.currentValue = this.value
  }

  beforeDestroy() {
    if (this.interval !== null) {
      clearInterval(this.interval)
    }
  }

  startAnimation() {
    if (this.interval !== null) {
      clearInterval(this.interval)
    }

    const step =
      (this.value - this.currentValue) / (this.time / this.tickInterval)
    const dir = this.value > this.currentValue ? 1 : -1

    this.interval = setInterval(() => {
      this.currentValue += step
      if (this.currentValue * dir >= this.value * dir) {
        this.currentValue = this.value
        clearInterval(this.interval!)
      }
    }, this.tickInterval)
  }

  protected render(h: CreateElement) {
    const data: VNodeData = {
      ref: 'elementRef',
    }
    if (this.$scopedSlots.default) {
      const slot = this.$scopedSlots.default({ value: this.formatedValue })
      return h(this.tag, data, slot)
    }
    return h(this.tag, data, this.formatedValue)
  }
}
